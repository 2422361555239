import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import ExternalLink from "./Links/ExternalLink";
import './Footer.scss';
import ReactMarkdown from "react-markdown";
import EmailLink from "./Links/EmailLink";

const Footer = () => {

    const {allLinks, company} = useStaticQuery(graphql`
        query Footer {
            allLinks: markdownRemark(frontmatter: {templateKey: {eq: "links"}}) {
                frontmatter {
                    links {
                        label
                        url
                    }
                    geodesy {
                        label
                        url
                    }
                    book {
                        label
                        url
                    }
                }
            },
            company: markdownRemark(frontmatter: {templateKey: {eq: "company"}}) {
                frontmatter {
                    name
                    city
                    postcode
                    address
                    email
                    phone
                    bankNumber
                    bankSwift
                    workingHours {
                        day
                        hours
                    }
                }
            }
        }
    `);

    const {links, geodesy, book} = allLinks.frontmatter;

    return (
        <footer className="footer has-text-white-ter" style={{position: 'relative'}}>
            <div className="content has-text-centered">
            </div>
            <div className="content has-text-centered">
                <div className="container">
                    <div className="columns is-multiline">
                        <ColumnHeader header="Kontakt:"/>
                        <Contact company={company}/>
                        <ColumnHeader header="Przydatne linki:"/>
                        <LinksColumn items={links}/>
                        <ColumnHeader header="Wydziały ksiąg wieczystych:"/>
                        <LinksColumn items={book}/>
                        <ColumnHeader header="Wydziały geodezji:"/>
                        <LinksColumn items={geodesy}/>
                    </div>
                </div>
            </div>
        </footer>
    )
}

const ColumnHeader = ({header}) => {
    return (
        <div className="column is-3-desktop is-6-tablet footer-column">
            <h4 className="has-text-uppercase has-text-white has-text-left" style={{margin: 0,}}>{header}</h4>
        </div>
    )
}

const LinksColumn = ({items}) => {
    return (
        <div className="column is-3-desktop is-6-tablet footer-column">
            {items.map((item) => {
                return <ExternalLink key={item.label} href={item.url}
                                     classNames="footer-link">{item.label}</ExternalLink>
            })}
        </div>
    )
}

const Contact = ({company}) => {
    return (
        <div className="column is-3-desktop is-6-tablet has-text-left footer-column">
            <ReactMarkdown source={company.frontmatter.name}
                           renderers={{
                               paragraph: (props) => <p className="has-text-weight-medium">{props.children}</p>
                           }}/>
            <p>
                <ExternalLink classNames="has-text-white"
                              href={"https://goo.gl/maps/Nk6JvmdjeqwwFPJ99"}>{company.frontmatter.postcode} {company.frontmatter.city}, {company.frontmatter.address}</ExternalLink>
            </p>

            <p>
                Konto Kancelarii: <br/>
                {company.frontmatter.bankNumber} <br/>
                Kod BIC (Swift) banku: {company.frontmatter.bankSwift}
            </p>
            <p>email: <EmailLink email={company.frontmatter.email} className="has-text-white"/></p>
            <span><ReactMarkdown source={company.frontmatter.phone}/></span>
        </div>
    )
}

export default Footer
