import React from 'react'
import {Helmet} from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../scss/all.scss'
import useSiteMetadata from './SiteMetadata'
import {withPrefix} from 'gatsby'

const Layout = ({children, location, meta}) => {
    const {title, description, siteUrl} = useSiteMetadata()

    const pageTitle = (meta && meta.title) || title;
    const pageDescription = (meta && meta.description) || description;

    return (
        <div>
            <Helmet>
                <html lang="pl"/>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription}/>
                <link rel="canonical" href={`${siteUrl}${location.pathname}`}/>
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap" rel="stylesheet"/>

                <meta property="og:type" content="business.business"/>
                <meta property="og:title" content={pageTitle}/>
                <meta property="og:url" content="/"/>
                <meta
                    property="og:image"
                    content={`${withPrefix('/')}img/og-image.jpg`}
                />
            </Helmet>
            <Navbar location={location}/>
            <div>{children}</div>
            <Footer/>
        </div>
    )
}

export default Layout
