import React from 'react';

const Email = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 31.271 23.325">
            <path
                d="M28.22.5H3.051A3.054,3.054,0,0,0,0,3.551V20.775a3.054,3.054,0,0,0,3.051,3.051H28.22a3.054,3.054,0,0,0,3.051-3.051V3.551A3.054,3.054,0,0,0,28.22.5ZM3.051,2.025H28.22a1.52,1.52,0,0,1,1.08.449L16.321,10.946a1.522,1.522,0,0,1-1.69-.013L2.011,2.436a1.52,1.52,0,0,1,1.04-.411ZM29.746,20.775A1.527,1.527,0,0,1,28.22,22.3H3.051a1.527,1.527,0,0,1-1.525-1.525V3.948L13.778,12.2a3.044,3.044,0,0,0,3.377.025l12.59-8.217Zm0,0"
                transform="translate(0 -0.5)"/>
        </svg>
    );
};

export default Email;