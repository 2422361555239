import React from 'react';

const Phone = ({size = {}, style = {}}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 28.259 28.259" {...size} style={style}>
            <g transform="translate(0)">
                <path
                    d="M20.31,28.259a7.873,7.873,0,0,1-2.7-.483A28.8,28.8,0,0,1,7.15,21.11,28.8,28.8,0,0,1,.483,10.651a7.816,7.816,0,0,1-.4-3.842A8.015,8.015,0,0,1,4.2.941,7.869,7.869,0,0,1,7.971,0a.883.883,0,0,1,.863.7l1.386,6.468a.883.883,0,0,1-.239.81L7.613,10.344a23.245,23.245,0,0,0,10.3,10.3l2.368-2.368a.884.884,0,0,1,.81-.239l6.468,1.386a.883.883,0,0,1,.7.863,7.869,7.869,0,0,1-.941,3.768,8.015,8.015,0,0,1-5.867,4.12,7.855,7.855,0,0,1-1.141.084ZM7.266,1.8a6.188,6.188,0,0,0-5.123,8.243A26.785,26.785,0,0,0,18.212,26.117a6.188,6.188,0,0,0,8.243-5.123l-5.262-1.128L18.72,22.34a.883.883,0,0,1-1,.175A25,25,0,0,1,5.745,10.54a.883.883,0,0,1,.175-1L8.393,7.067Z"
                    transform="translate(0 0)"/>
                <path
                    d="M254.149,15.012a.883.883,0,0,1-.883-.883A12.377,12.377,0,0,0,240.9,1.766.883.883,0,1,1,240.9,0a14.129,14.129,0,0,1,14.129,14.129A.883.883,0,0,1,254.149,15.012Z"
                    transform="translate(-226.772)"/>
                <path
                    d="M250.617,75.48a.883.883,0,0,1-.883-.883,8.841,8.841,0,0,0-8.831-8.831.883.883,0,1,1,0-1.766,10.609,10.609,0,0,1,10.6,10.6A.883.883,0,0,1,250.617,75.48Z"
                    transform="translate(-226.772 -60.468)"/>
                <path
                    d="M247.085,135.948a.883.883,0,0,1-.883-.883,5.3,5.3,0,0,0-5.3-5.3.883.883,0,1,1,0-1.766,7.073,7.073,0,0,1,7.065,7.065A.883.883,0,0,1,247.085,135.948Z"
                    transform="translate(-226.772 -120.935)"/>
            </g>
        </svg>
    );
};

export default Phone;