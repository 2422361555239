import React from 'react';

const Clock = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27 27">
            <g transform="translate(-1247 -22)">
                <g transform="translate(1247 22)" fill={'#fff'} strokeWidth={'1.5px'} stroke={"#242847"} className={"fill-none"}>
                    <circle cx="13.5" cy="13.5" r="13.5" stroke={"none"} className={"fill-none"}/>
                    <circle cx="13.5" cy="13.5" r="12.5" fill={"none"} className={"fill-none"}/>
                </g>
                <path className={"fill-none"} d="M1407.5-3190.533V-3183h4.394" transform="translate(-147 3219)" strokeWidth={'1.5px'} fill={"none"} stroke={"#242847"} strokeLinecap={"round"} strokeLinejoin={"round"}/>
            </g>
        </svg>
    );
};

export default Clock;