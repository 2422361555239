import React from 'react';
import PropTypes from 'prop-types';

const EmailLink = ({email, children, className}) => {
    return (
        <a href={`mailto:${email}`} className={className}>
            {children || email}
        </a>
    );
};

EmailLink.propTypes = {
    email: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default EmailLink;