import React, {useEffect, useState} from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import './Navbar.scss';
import PhoneLink from "./Links/PhoneLink";
import EmailLink from "./Links/EmailLink";
import Phone from "./Icons/Phone";
import Email from "./Icons/Email";
import Clock from "./Icons/Clock";
import Img from "gatsby-image";

const Navbar = ({location, data}) => {

    const {company} = useStaticQuery(graphql`
        query Navbar {
            company: markdownRemark(frontmatter: {templateKey: {eq: "company"}}) {
                frontmatter {
                    email
                    menuPhonePrimary
                    menuPhoneSecondary
                    menuWorkingHours
                    logo {
                        childImageSharp {
                            fixed(width: 45, height: 50) {
                                ...GatsbyImageSharpFixed_noBase64
                            }
                        }
                    }
                }
            }
        }
    `);

    const [active, setActive] = useState(false);
    const [navBarActiveClass, setNavBarActiveClass] = useState('');

    useEffect(() => {
        setNavBarActiveClass(active ? 'is-active' : '');
    }, [active])

    const toggleHamburger = () => {
        setActive((prevActive) => !prevActive);
    }

    const renderMenuItem = (path, slug) => {
        return (
            <Link
                className={`navbar-item ${path === location.pathname ? 'is-menu-active' : 'is-light-gray'}`}
                to={path}>
                {slug}
            </Link>
        )
    };

    return (
        <nav
            className="navbar is-transparent is-fixed-top-desktop"
            role="navigation"
            aria-label="main-navigation"
        >
            <div className="navbar-container">
                <div className="navbar-header is-hidden-touch">
                    <div className="container">
                        <div className="navbar-end">
                            <PhoneLink phone={company.frontmatter.menuPhonePrimary} className="navbar-item" style={{paddingRight: '6px'}}>
                                <Phone/>
                                <span>{company.frontmatter.menuPhonePrimary}, </span>
                            </PhoneLink>
                            <PhoneLink phone={company.frontmatter.menuPhoneSecondary} className="navbar-item" style={{paddingLeft: '0'}}>
                                <span>{company.frontmatter.menuPhoneSecondary}</span>
                            </PhoneLink>
                            <EmailLink email={company.frontmatter.email} className="navbar-item">
                                <Email/>
                                <span>{company.frontmatter.email}</span>
                            </EmailLink>
                            <div className={'navbar-item'}>
                                <Clock/>
                                <span>{company.frontmatter.menuWorkingHours}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbar-container container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-brand__link" title="Logo">
                        <Img fixed={company.frontmatter.logo.childImageSharp.fixed} alt={"Logo kancelarii"}/>
                        <span className="navbar-brand__name">
                            <span className="navbar-brand__name__top">KANCELARIA NOTARIALNA</span>
                            <span className="navbar-brand__name__bottom">Zofia Bzdyl i Małgorzata Bereś-Bzdyl</span>
                        </span>
                        </Link>
                        {/* Hamburger menu */}
                        <div
                            className={`navbar-burger burger ${navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => toggleHamburger()}
                        >
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${navBarActiveClass}`}
                    >
                        <div className="navbar-end has-text-centered">
                            {renderMenuItem("/", "O nas")}
                            {renderMenuItem("/notariusze", "Notariusze")}
                            {renderMenuItem("/czynnosci_notarialne", "Czynności notarialne")}
                            {renderMenuItem("/koszty", "Koszty")}
                            {renderMenuItem("/dojazd", "Dojazd")}
                            {renderMenuItem("/kontakt", "Kontakt")}
                            <PhoneLink phone={company.frontmatter.menuPhonePrimary} className="navbar-item is-hidden-desktop" style={{display: "inline-block", paddingRight: '6px'}}>
                                <Phone size={{width: '17px', height: '17px'}} style={{marginRight: '8px'}}/>
                                <span>{company.frontmatter.menuPhonePrimary},</span>
                            </PhoneLink>
                            <PhoneLink phone={company.frontmatter.menuPhoneSecondary} className="navbar-item is-hidden-desktop" style={{display: "inline-block", paddingLeft: '0'}}>
                                <span>{company.frontmatter.menuPhoneSecondary}</span>
                            </PhoneLink>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
export default Navbar
